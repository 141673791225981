import React, { useState } from "react";
import styled from "styled-components";
import laptop from "../img/laptop.svg";
import phone from "../img/phone.svg";
import jcesardesign from "../img/projects/jcd.jpeg";
import jcesardesignM from "../img/projects/jcdM.jpeg";
import leserumelixir from "../img/projects/leserum.jpeg";
import leserumelixirM from "../img/projects/leserumM.jpeg";
import museedesutton from "../img/projects/musee.jpeg";
import museedesuttonM from "../img/projects/museeM.jpeg";
import elesig from "../img/projects/elesig1.jpeg";
import elesigM from "../img/projects/elesigM.jpeg";
import sage from "../img/projects/sage.jpeg";
import sageM from "../img/projects/sageM.jpeg";
import panda from "../img/projects/panda1.jpeg";
import pandaM from "../img/projects/pandaM.jpeg";
import west from "../img/projects/westParc1.jpeg";
import westM from "../img/projects/westParcM.jpeg";
import lm from "../img/projects/lmatelier.jpeg";
import lmM from "../img/projects/lmatelierM.jpeg";
import utah from "../img/projects/utah.jpeg";
import pickle from "../img/projects/pickle.jpeg";
import pickleM from "../img/projects/pickleM.jpeg";
import businessCards from "../img/projects/businesscards.jpeg";
import form from "../img/projects/form.jpeg";
import { useStateContext } from "../useGlobalContext";
import { AnimatePresence, motion } from "framer-motion";
import { AiOutlineClose } from "react-icons/ai";

import { useMediaQuery } from "react-responsive";
import { Helmet } from "react-helmet-async";

const Projects = () => {
  const isPhone = useMediaQuery({ maxWidth: 992 });
  const [siteIndex, setSiteIndex] = useState(0);
  const [menu, setMenu] = useState("web");

  const webCards = [
    {
      img: pickle,
      imgM: pickleM,
      url: "https://www.pickleballsutton.ca",
      typeFR: "Site vitrine",
      typeEN: "Showcase website",
      title: "Club Pickleball Sutton",
      DescriptionFR:
        "Le Club Pickleball Sutton est un club de pickleball situé à Sutton, Québec. J'ai travaillé avec François, Louise et Sylvie pour la création de leur site web. Ils sont dévoués à développer le pickleball dans la région de Sutton.",
      DescriptionEN:
        "The Club Pickleball Sutton is a pickleball club located in Sutton, Quebec. I worked with François, Louise and Sylvie for the creation of their website. They are dedicated to developing pickleball in the Sutton region.",
    },
    {
      img: museedesutton,
      imgM: museedesuttonM,
      title: "Musée des communications et d'histoire de Sutton",
      typeFR: "Site vitrine avec point de vente",
      typeEN: "Showcase website with payment access",
      url: "https://www.museedesutton.com",
      DescriptionFR:
        "Le Musée des Communications et d'histoires de Sutton est un charmant petit musée, qui organise des expositions temporaires thématiques, auquel j'ai eu le plaisir de collaborer pour la création de leur site web.",
      DescriptionEN:
        "The Sutton Museum of Communications and History is a charming little museum, which organizes thematic temporary exhibitions, with which I had the pleasure of collaborating for the creation of their website.",
    },
    {
      img: elesig,
      imgM: elesigM,
      url: "https://www.elesig.fr",
      typeFR: "E-Shop avec backoffice pesonnalisé",
      typeEN: "E-Shop with custom backoffice",
      title: "Elèsig",
      DescriptionFR:
        "Elèsig est une boutique en ligne de vêtements pour femme. C'est une gamme de prêt-à-porter féminin avec de très belles pièces, où l’idée première est d’être le moins polluant possible. J'ai travaillé avec Cindy, qui est passionnée par la mode et l'écologie, pour la création de son site web.",
      DescriptionEN:
        "Elèsig is an online store for women's clothing and accessories. It is a range of feminine ready-to-wear with very beautiful pieces, where the first idea is to be as least polluting as possible. I worked with Cindy, who is passionate about fashion and ecology, for the creation of her website.",
    },
    {
      img: sage,
      imgM: sageM,
      url: "https://sageetsauvage.vercel.app",
      typeFR: "E-Shop avec backoffice pesonnalisé",
      typeEN: "E-Shop with custom backoffice",
      title: "Sage et Sauvage",
      DescriptionFR:
        "Sage et Sauvage est une boutique en ligne de vêtements pour bébés et enfants durables et minimalistes pour une garde robe intemporelle. J'ai travaillé avec Nina, qui est passionnée par la mode et la création. Son site contient également un backoffice personnalisé pour la gestion de ses produits, commandes, clients, etc.",
      DescriptionEN:
        "Sage et Sauvage is an online store for durable and minimalist baby and children's clothing for a timeless wardrobe. I worked with Nina, who is passionate about fashion and creation. Her site also contains a custom back office for managing her products, orders, customers, etc.",
    },
    {
      img: west,
      imgM: westM,
      url: "https://www.west-parc.fr",
      typeFR: "Site vitrine",
      typeEN: "Showcase website",
      title: "West Parc",
      DescriptionFR:
        "West Parc est un centre récréatif avec Bowling, Laser Game, Trampoline parc, Billard, Arcades, Bar et Restaurant à Dinard, France. J'ai eu le plaisir de travailler avec Ludovic et Guillaume pour la création de leur site web.",
      DescriptionEN:
        "West Parc is a recreational center with Bowling, Laser Game, Trampoline park, Billiards, Arcades, Bar and Restaurant in Dinard, France. I had the pleasure of working with Ludovic and Guillaume for the creation of their website.",
    },
    {
      img: panda,
      imgM: pandaM,
      url: "https://www.lacabanedesptitspandas.fr/",
      typeFR: "Site vitrine",
      typeEN: "Showcase website",
      title: "La Cabane des P'tits Pandas",
      DescriptionFR:
        "La Cabane des P'tits Pandas est une micro-crèche dédiée à la garde d'enfants, offrant un environnement sécurisé et stimulant. J'ai travaillé avec David, qui travaille dur pour que son entreprise aide les enfants à s'épanouir.",
      DescriptionEN:
        "La Cabane des P'tits Pandas is a daycare for children, offering a safe and stimulating environment. I've had the please of working with David, who works tirelessly to have his business strive to help children develop.",
    },
    {
      img: leserumelixir,
      imgM: leserumelixirM,
      url: "https://leserumelixir.vercel.app",
      typeFR: "Site vitrine",
      typeEN: "Showcase website",
      title: "Le Serum Elixir",
      DescriptionFR:
        "Le Serum Elixir est une petite entreprise de produits de soins de la peau, biologiques. J'ai eu le plaisir de travailler avec Marie-France sur la présentation digitale de sa gamme de produits et de son expérience. Elle est passionnée par son art, ce qui se reflète sur son site web. ",
      DescriptionEN:
        "The Serum Elixir is a small business of organic skincare products. I had the pleasure of working with Marie-France on the digital presentation of her product range and her experience. She is passionate about her craft, which is reflected on her website.",
    },

    {
      img: lmM,
      imgM: lm,
      url: "https://atelier25.vercel.app",
      typeFR: "Site vitrine",
      typeEN: "Showcase website",
      title: "LM Atelier 25",
      DescriptionFR:
        "LM Atelier 25 est un atelier de tatouage à Saint-Lunaire, France, qui crée des tatouages personnalisés et uniques. Son site web reflète son style et son art.",
      DescriptionEN:
        "LM Atelier 25 is a tattoo studio in Saint-Lunaire, France, that creates custom and unique tattoos. Its website reflects its style and art.",
    },
    {
      img: jcesardesign,
      imgM: jcesardesignM,
      url: "https://jcesardesign.vercel.app",
      typeFR: "Site vitrine",
      typeEN: "Showcase website",
      title: "J. Cesar Design",
      DescriptionFR: "Notre premier site web.",
      DescriptionEN: "Our first website.",
    },
  ];

  const graphicCards = [
    {
      img: utah,
      titleFR: "Illustration digitale",
      titleEN: "Digital illustration",
      typeFR: "Poster",
      typeEN: "Poster",
    },
    {
      img: businessCards,
      titleFR: "Design graphique",
      titleEN: "Graphic design",
      typeFR: "Carte de visite",
      typeEN: "Business Cards",
    },
    {
      img: form,
      titleFR: "Document PDF",
      titleEN: "PDF Document",
      typeFR: "Formulaire",
      typeEN: "Form",
    },
  ];

  const communityCards = [];
  const { french } = useStateContext();
  const [graphicCard, setGraphicCard] = useState(null);

  return (
    <ProjectsWrapper>
      <Helmet>
        <title>Portfolio | JULES Agence Digitale</title>
        <meta
          name="description"
          content="Découvrez le portfolio de JULES Agence Digitale, mettant en avant nos projets réussis en développement web, gestion de communauté et design graphique."
        />
        <link rel="canonical" href="https://www.julesdigital.com/projects" />
      </Helmet>
      <ul
        className="menu"
        style={{ touchAction: menu === "graphic" ? "none" : "auto" }}
      >
        <li onClick={() => setMenu("web")}>
          {french ? "Développement web" : "Website Development"}
          {menu === "web" ? (
            <motion.div className="highlight" layoutId="highlight" />
          ) : null}
        </li>
        <li onClick={() => setMenu("graphic")}>
          {french ? "Design graphique" : "Graphic Design"}
          {menu === "graphic" ? (
            <motion.div className="highlight" layoutId="highlight" />
          ) : null}
        </li>
        {/* <li onClick={() => setMenu("community")}>
          Community Management
          {menu === "community" ? (
            <motion.div
              className="highlight"
              layoutId="highlight"
            />
          ) : null}
        </li> */}
      </ul>
      <AnimatePresence>
        {menu === "web" && (
          <>
            <motion.div
              className="web-container"
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,
                transition: { duration: 0.2, delay: 0.2 },
              }}
              exit={{ opacity: 0, transition: { duration: 0.1 } }}
            >
              <div className="cards-container">
                <h2>{webCards[siteIndex].title}</h2>
                <h3>
                  {french
                    ? webCards[siteIndex].typeFR
                    : webCards[siteIndex].typeEN}
                </h3>
                <p>
                  {french
                    ? webCards[siteIndex].DescriptionFR
                    : webCards[siteIndex].DescriptionEN}
                </p>
              </div>
              <div className="device-container">
                <div>
                  <div className="container-laptop">
                    <a
                      rel="noreferrer noopener"
                      target="_blank"
                      href={
                        webCards[siteIndex].url.includes("sageetsauvage")
                          ? "https://sageetsauvage-j7r0ds9ix-rboulanger1992.vercel.app/"
                          : webCards[siteIndex].url
                      }
                    >
                      <img src={laptop} alt="" className="laptop" />

                      <img
                        src={webCards[siteIndex].img}
                        alt=""
                        className="musee"
                      />
                    </a>
                  </div>
                  <div className="container-phone">
                    <a
                      rel="noreferrer noopener"
                      target="_blank"
                      href={
                        webCards[siteIndex].url.includes("sageetsauvage")
                          ? "https://sageetsauvage-j7r0ds9ix-rboulanger1992.vercel.app/"
                          : webCards[siteIndex].url
                      }
                    >
                      <img src={phone} alt="" className="phone" />
                      <img
                        src={webCards[siteIndex].imgM}
                        alt=""
                        className="musee"
                      />
                    </a>
                  </div>
                </div>
                <a
                  rel="noreferrer noopener"
                  target="_blank"
                  href={
                    webCards[siteIndex].url.includes("sageetsauvage")
                      ? "https://sageetsauvage-j7r0ds9ix-rboulanger1992.vercel.app/"
                      : webCards[siteIndex].url
                  }
                >
                  {webCards[siteIndex].url}
                </a>
              </div>
            </motion.div>
            <motion.div
              style={{ width: "90vw", display: "grid", placeItems: "center" }}
              initial={{ opacity: 0 }}
              animate={{
                opacity: 1,

                transition: { duration: 0.2, delay: 0.2 },
              }}
              exit={{ opacity: 0 }}
            >
              <div className="carousel-container">
                <div className="left block" />
                <div className="image-container">
                  {webCards.map((card, index) => (
                    <div key={index}>
                      <img
                        src={card.img}
                        alt={card.title}
                        key={index}
                        onMouseEnter={() => setSiteIndex(index)}
                      />
                      {siteIndex === index ? (
                        <motion.div
                          className="image-border"
                          layoutId="outline"
                        />
                      ) : null}
                    </div>
                  ))}
                </div>
                <div className="right block" />
              </div>
            </motion.div>
          </>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {menu === "graphic" && (
          <motion.div
            className="graphic-container"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.2, delay: 0.2 },
            }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
          >
            {graphicCards.map((card, index) => (
              <div className="cards-container" key={index}>
                <img
                  src={card.img}
                  alt={card.titleFR}
                  onClick={() => setGraphicCard(card.img)}
                />
                <div>
                  <h3>{french ? card.titleFR : card.titleEN}</h3>
                  <p>{french ? card.typeFR : card.typeEN}</p>
                </div>
              </div>
            ))}
            {graphicCard && !isPhone && (
              <div
                className="card-container"
                onClick={() => setGraphicCard(null)}
              >
                <div>
                  <img
                    src={graphicCard}
                    alt={`${graphicCard}`}
                    className="graphic-card"
                    onClick={(e) => e.stopPropagation()}
                  />
                  <AiOutlineClose className="close" size={50} />
                </div>
              </div>
            )}
          </motion.div>
        )}
      </AnimatePresence>
      <AnimatePresence>
        {menu === "community" && (
          <motion.div
            className="community-container"
            initial={{ opacity: 0 }}
            animate={{
              opacity: 1,
              transition: { duration: 0.2, delay: 0.2 },
            }}
            exit={{ opacity: 0, transition: { duration: 0.1 } }}
          >
            {communityCards.map((card, index) => (
              <div className="cards-container" key={index}></div>
            ))}
          </motion.div>
        )}
      </AnimatePresence>
    </ProjectsWrapper>
  );
};

export default Projects;

const ProjectsWrapper = styled.div`
  min-height: 77vh;
  margin: 0 5vw;
  margin-top: 15vh;
  --small: 20px;
  --medium: calc(var(--small) * 1.618);
  @media (max-width: 992px) {
    margin-top: 15vh;
  }
  .branch {
    position: fixed;
    bottom: 0;
    right: 0;
    height: 110vh;
    z-index: -50;
    opacity: 0.1;
    rotate: -15deg;
    transform-origin: right bottom;
  }
  .menu {
    display: flex;
    flex-direction: row;
    justify-content: center;
    list-style: none;
    width: 100%;
    gap: 5vw;
    margin-bottom: var(--medium);
    li {
      margin-right: 5vw;
      color: var(--second-text-color);
      position: relative;
      background: transparent;
      height: fit-content;
      width: fit-content;
      cursor: pointer;

      .highlight {
        width: 120%;
        height: 120%;
        background: rgba(195, 141, 94, 0.2);
        position: absolute;
        top: -10%;
        left: -10%;
        z-index: -1;
        border-radius: 5px;
      }
    }
    @media (max-width: 992px) {
      width: 90vw;
      li {
        text-align: center;
        margin-right: 0;
        font-size: 16px;
        .highlight {
          width: 120%;
          height: 150%;
          left: -10%;
          top: -25%;
        }
      }
    }
  }
  .web-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 90vw;
    margin-top: 12vh;
    @media (max-width: 992px) {
      flex-direction: column-reverse;
    }
    .cards-container {
      h2 {
        margin-bottom: var(--medium);
        font-size: var(--medium);
        color: var(--fifth-color);
      }
      h3 {
        font-size: var(--small);
        margin-bottom: var(--small);
        color: var(--second-text-color);
      }
      p {
        font-size: var(--small);
        color: var(--text-color);
        padding-right: var(--small);
        margin-bottom: var(--medium);
      }

      @media (max-width: 992px) {
        --small: 16px;
        width: 90vw;
        h2,
        h3 {
          text-align: center;
        }
        margin-top: var(--large);
        margin-bottom: 25vh;
        a {
          text-align: start;
        }
      }
    }

    .device-container {
      display: flex;
      flex-direction: column;
      div {
        display: flex;
        flex-direction: row;
        @media (max-width: 992px) {
          width: 90vw;
          margin: 0 auto;
          justify-content: center;
        }
        .container-laptop {
          margin-bottom: var(--small);
          a {
            position: relative;
            display: grid;
            place-items: center;
            height: max(16vh, 20vw);
            width: auto;
          }
          .laptop {
            height: inherit;
            width: inherit;
          }
          .musee {
            position: absolute;
            width: auto;
            top: 5%;
            height: 85%;
            width: 78%;
            z-index: -1;
          }
        }
        .container-phone {
          a {
            position: relative;
            display: grid;
            place-items: center;
            height: max(16vh, 20vw);
            width: auto;
          }
          .phone {
            height: inherit;
            width: inherit;
          }
          .musee {
            position: absolute;
            top: 2%;
            height: 96%;
            width: 90%;
            z-index: -1;
          }
        }
      }
    }
  }
  .carousel-container {
    position: fixed;
    bottom: 5vh;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
    width: 90vw;
    height: max(6vh, 6vw);
    .image-container {
      overflow-y: hidden;
      height: inherit;
      cursor: ew-resize;
      overflow-x: scroll;
      background: black;
      position: relative;
      max-width: 84vw;
      display: flex;
      flex-direction: row;
      div {
        width: max(10vh, 10vw);
        height: inherit;
        .image-border {
          position: absolute;
          top: 0;
          z-index: 5;
          width: inherit;
          height: max(6vh, 6vw);
          background: transparent;
          border: 2px solid var(--main-color);
        }
        height: inherit;
        img {
          width: inherit;
          height: inherit;
        }
      }
    }
    .block {
      height: inherit;
      width: 3vw;
      background: var(--fourth-color);
    }
    .left {
      border-top-left-radius: 10px;
      border-bottom-left-radius: 10px;
    }
    .right {
      border-top-right-radius: 10px;
      border-bottom-right-radius: 10px;
    }
  }
  .graphic-container {
    position: absolute;
    inset: 30vh 0 auto 0;
    display: grid;
    grid-template-columns: repeat(3, 1fr);
    place-items: center;
    grid-gap: 5vw;
    padding: 0 5vw;
    padding-bottom: 15vh;
    .cards-container {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      align-items: center;
      height: 100%;
      div {
        h3 {
          color: var(--fifth-color);
        }
        p {
          color: var(--second-text-color);
        }
      }
      img {
        max-height: 25vw;
        max-width: 40vw;
        cursor: pointer;
        margin-bottom: 40px;
      }
    }
    @media (max-width: 992px) {
      grid-template-columns: 1fr;
      padding-bottom: 10vh;
      .cards-container {
        img {
          max-height: max-content;
          max-width: 90vw;
        }
      }
    }
    .card-container {
      position: fixed;
      inset: 0 0 0 0;
      backdrop-filter: blur(10px);
      display: grid;
      place-items: center;
      overflow: scroll;
      overscroll-behavior: contain;
      div {
        display: flex;
        flex-direction: column;
        justify-content: space-between;
        align-items: center;
        img {
          margin-top: 20vh;
          width: 54vw;
          height: auto;
          margin-bottom: 10vh;
        }
        .close {
          margin-bottom: 10vh;
          cursor: pointer;
        }
      }
    }
  }
`;
